<template>
    <div>
        <div class="plans-notification" v-if="billingHistory.length > 0">
            <h6>{{ days }} : {{ hours }} : {{ minutes }} : {{ seconds }} <span class="dayss">days until renewal!</span> <span class="membership"  v-if="days == 0 && hours == 0 && minutes == 0">Renew your Smart associate membership now</span> <span class="renew" v-if="days == 0 && hours == 0 && minutes == 0" @click="openPlansModal()">Renew now</span></h6>
            <!-- <h6>Plan not activated or has expired <span>Explore plans </span>to activate</h6> -->
        </div>

        <div class="plans-billing-text">
            <h4>Plans & Billings </h4>
        </div>

        <div class="current-plan" v-if="plansData.next_bill_amount">
            <h6>Current plan</h6>
            <h3 v-if="currentPlan.smartplan.name">{{ currentPlan.smartplan.name }}</h3>
            <h3 v-if="currentPlan === null">No active plan</h3>
            <h4  v-if="currentPlan === null">Select a plan to start enjoying the benefits</h4>
            <h4 v-if="currentPlan != null && currentPlan.confirmed == 'YES' && currentPlan.smartplan.name != 'Smart Vendor'">Your next bill is <span>₦{{ Number(plansData.next_bill_amount).toLocaleString() }}</span> on <span>{{ plansData.next_bill_date }}</span></h4>
            <h4 v-if="currentPlan != null && currentPlan.confirmed == 'NO'" style="color: #EB0000">Awaiting confirmation</h4>
            <hr>
            <h4 @click="openPlansModal()" class="explore-text">Explore Plans <span class="fa fa-chevron-right"></span></h4>
        </div>

        <div class="text-center"  v-if="loading">
            <Loader />
        </div>


        <div class="billing-history-holder" v-if="!loading">
            <h6>Billig history</h6>
            

            <div class="table-responsive table-holder" v-if=" billingHistory.length > 0 || loading">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <!-- <th>No.</th> -->
                            <th scope="col">Billing date</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th scope="col">plan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) of billingHistory"
                            :key="index">
                            <!-- <th scope="row">1</th> -->
                            <td>{{ item.billing_date }}</td>
                            <td>₦{{ Number(item.amount).toLocaleString() }}</td>
                            <td><span :class="[item.confirmed === 'YES' ? 'status-paid' : 'status-notpaid']">{{ item.confirmed === 'YES' ? 'Paid' : 'Not paid'}}</span></td>
                            <td v-if="item">{{ item.smartplan.name }}</td>
                        </tr>            
                    </tbody>
                </table>
                <!-- <div
                class="empty text-center m-5"
                v-if="leadLog.length === 0 && fetchData2 === 'loaded'"
                >
                No Records
                </div> -->
            </div>

            <div class="" v-else>
                <EmptyState :emptyData="emptyOptions"></EmptyState>
                <div class="empty-btn">
                    <button @click="openPlansModal()" class="btn explore-btn">Explore plans</button>
                </div>
            </div>

            <!-- <div class="loading-container text-center" v-if="fetchData2 === 'loaded'">
                <Loader />
            </div> -->
            <!-- <div class="loading-container text-center" v-if="fetchData3 === 'loading'">
                <Loader />
            </div> -->
            
            

        </div>

        <PlansBillingsModal :currentPlan="currentPlan" :countDown="countDown"></PlansBillingsModal>
        <PlansPaymentOptions></PlansPaymentOptions>

    </div>
</template>

<script>
import PlansBillingsModal from './PlansBillingsModal.vue';
import PlansPaymentOptions from './PlansPaymentOptions.vue';
import EmptyState from './EmptyState.vue';
import Loader from "./Loader";
// import $ from "jquery";
// import authentication from '../services/authentication';
import plansbillings from '../services/plansBillings';

export default {
    name: "PlansBillings",
    components: {
        PlansBillingsModal,
        PlansPaymentOptions,
        EmptyState,
        Loader
    },
    data() {
        return {
            loading: false,
            plansData: "",
            currentPlan: "",
            billingHistory: [],
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            countDown: {},
            // paymentData: "",
        }
    },
    created() {
       this.emptyOptions = {
        header: 'No billing history',
        option: 'Select your prefered billing plans and enjoy the benefits.',
       }
    },
    mounted() {
        this.fetchPlansBillings();
    },  
    methods: {
        // receiveData(data) {
        //     console.log('Data from child:', data);
        // },
        openPlansModal() {
            return this.$root.$refs.plans.plansModal();
        },
        async fetchPlansBillings() {
            this.loading = true;
            await plansbillings.userPlans().then(data => {
                // console.log("Plans and billings", data)
                this.loading = false;
                if (data.success) {
                    this.plansData = data;
                    if (data.current_plan) {
                        this.currentPlan = data.current_plan;
                        this.startCountdown();
                    }
                    this.billingHistory = data.billing_history;
                    
                } else {
                    this.$toast.error(data.error);
                }
            }).catch(() => {
                this.loading = false;
                this.$toast.error("please check your network and refresh the page");
           });
        },
        startCountdown() {
            setInterval(() => {
                if (this.currentPlan) {
                    const now = new Date().getTime();
                    const distance = new Date(this.currentPlan.expiry_date).getTime() - now;

                    this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    this.countDown = {
                        days: this.days,
                        hours: this.hours,
                        minutes: this.minutes,
                        seconds: this.seconds
                    }
                }
            }, 1000);
        }

    },
}
</script>


<style lang="scss" scoped>

$primary: #0033EA;
$white: #ffffff;
$asheGrey: #555C74;

.plans-notification {
    background: $primary;
    min-height: 4px;
    padding: 24px;
    border-radius: 8px;
    h6 {
        color: $white;
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        margin-bottom: 0;
        .dayss {
            font-weight: 400;
        }
        .membership {
            font-weight: 200;
        }
        .renew {
            cursor: pointer;
            font-weight: 700;
            border-bottom: 1px solid $white;
        }
    }
}

.plans-billing-text {
    margin-top: 30px;
    margin-bottom: 15px;
    h4 {
        font-family: Lato;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #2B3352;
    }
}

.current-plan {
    min-height: 181px;
    padding: 24px;
    border-radius: 8px;
    border: 1px 0px 0px 0px;
    h6 {
        font-family: Lato;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #2B3352;
    }
    h3 {
        font-family: Lato;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #2B3352;
    }
    h4 {
        font-family: Lato;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #555C74;
        span {
            font-weight: 700;
        }
    }
    .explore-text {
        color: #2B3352 !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
        cursor: pointer;
    }
}

.current-plan {
    border: 1px solid #E5E7EB;
    background: $white;
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #555C74;
    }
}


.billing-history-holder {
  box-shadow: 0px 1px 2px 0px #1018280F;
  box-shadow: 0px 1px 3px 0px #1018281A;
  border-radius: 8px;
  background: $white;
  margin-top: 20px;
  h6 {
    padding: 20px 30px;
    font-family: Lato;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #555C74;
  }
}

.status-paid {
  background: #ECFDF3;
  color: #2BC300;
  padding: 5px 10px;
  border-radius: 50px;
  text-transform: capitalize;
}
.status-notpaid {
  background: #fdefec;
  color: #c11301;
  padding: 5px 10px;
  border-radius: 50px;
  text-transform: capitalize;
}


.active-table {
  background: #FAFBFF;
  color: $primary !important;
  border-radius: 8px;
}

// Table section 

.table-holder {
  box-shadow: 0px 1px 2px 0px #1018280F;
  box-shadow: 0px 1px 3px 0px #1018281A;
  border: 1px solid #EAECF0;
  border-radius: 8px;
}
.table thead th {
  border-bottom: none;
}

thead {
  background: #F9FAFB;
  tr {
    // border: 1px solid #EAECF0;
    // border-top-left-radius: 10px !important;
    // background: #F9FAFB;

    th {
      font-family: Lato;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      color: #667085;
    }
  }
}

tbody {
  tr {
    td, th {
      border-top: 1px solid #EAECF0;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: $asheGrey;
      padding: 22px 10px;
    }
  }
}

.empty-btn {
    text-align: center;
    padding-bottom: 40px;
}

.explore-btn {
    background: $primary;
    color: $white;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    min-height: 40px;
    outline: 0;
    box-shadow: none;
}

</style>