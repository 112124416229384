<template>
    <div>
        <div class="modal fade" tabindex="-1" id="plansOptions">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title modal-top-text">Choose Payment method</h5>
                        <button @click="activePayment = ''" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="amount-section">
                            <h6>{{ activePayment === 'transfer' ? 'Transfer' : 'Deduct' }}</h6>
                            <h3>₦{{ Number(paymentData.amount).toLocaleString() }}</h3>
                            <p>{{ activePayment === 'transfer' ? 'To' : 'From' }}</p>
                        </div>
                        <div v-if="activePayment != 'transfer'">
                            <div class="payment-option-list" :class="[activePayment === 'smart_wallet' ? 'activeBtn' : '']">
                                <button class="btn" @click="activePayment = 'smart_wallet'">
                                    <div>
                                        <img src="../assets/icons/smart-logo.svg" alt="wallet">
                                        <span>Smart Wallet</span>
                                    </div>
                                    <div class="rouded-cycle"></div>
                                </button>
                            </div>

                            <div class="payment-option-list" :class="[activePayment === 'transfer' ? 'activeBtn' : '']">
                                <button class="btn"  @click="activePayment = 'transfer'">
                                    <div>
                                        <img src="../assets/subscription/bank-transfer.svg" alt="transfer">
                                        <span> Bank Transfer</span>
                                    </div>
                                    <div class="rouded-cycle"></div>
                                </button>
                            </div>
                            <div class="payment-option-list" :class="[activePayment === 'paystack' ? 'activeBtn' : '']">
                                <!-- <button class="btn"  @click="activePayment = 'paystack'; $refs.paystack.click()">
                                    <div>
                                            <img src="../assets/subscription/paystack-blue.svg" alt="paystack">
                                            <span>Paystack</span>
                                    </div>
                                    <div class="rouded-cycle"></div>
                                </button> -->
                                <paystack
                                    :channels="channels"
                                    :amount="paymentData.amount * 100"
                                    :email="email"
                                    :paystackkey="PUBLIC_KEY"
                                    :reference="reference"
                                    :callback="processPayment"
                                    :close="close"
                                    class="btn"
                                    type="button"
                                    @click="$refs.paystack.click()"
                                >
                                <div>
                                            <img src="../assets/subscription/paystack-blue.svg" alt="paystack">
                                            <span>Paystack</span>
                                    </div>
                                    <div class="rouded-cycle"></div>
                                </paystack>
                            </div>
                        </div>

                        <div  v-if="activePayment === 'transfer'">
                            <div class="transfer-holder">
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <div class="bank-details">
                                            <h6>Bank Name</h6>
                                            <h5>Stanbic IBTC</h5>
                                        </div>
                                        <div class="bank-details">
                                            <h6>Account Name</h6>
                                            <h5>Shelta Panacea Limited</h5>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="bank-details">
                                            <h6>Account Number</h6>
                                            <h5>0050024451</h5>
                                        </div>
                                        <!-- <div class="bank-details">
                                            <h6>Expires in</h6>
                                            <h5>24:00</h5>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="directionBtns">
                                <button class="btn confirm" @click="closePlansModal()" data-dismiss="modal" aria-label="Close">Confirm payment</button>
                                <button class="btn change" @click="activePayment = ''">Change payment method</button>
                            </div> -->
                        </div>
                        

                        <div class="agree-btn">
                            <button v-if="activePayment != ''" class="btn change" @click="activePayment = ''">Change payment method</button>
                            <button class="btn" :disabled="paymentLoader || activePayment == ''" @click="submitPayment()">
                               Continue
                               <i
                                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                v-if="paymentLoader"
                            ></i>
                            </button>
                        </div>
                    </div>
               
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import authentication from '../services/authentication';
import { EventBus } from './EventBus';
import paystack from "vue-paystack";
import { staticdata } from "../data";
import $ from "jquery";
import plansbillings from '../services/plansBillings';
export default {
    name: "PlansPaymentOptions",
    // props: ['paymentData'],
    components: {
        paystack,
    },
    data() {
        return {
            loading: false,
            paymentLoader: false,
            activePayment: "",
            paymentData: "",
            full_name: "",
            email: "",
            PUBLIC_KEY: staticdata.paystackkeylive,
            // PUBLIC_KEY: staticdata.paystackkeytest,
            user: "",
            reference: "",
            channels: ["card", "bank", "ussd", "qr", "bank_transfer"],
            // user: "",
        }
    },
    created() {
        const user = this.$store.getters.getUser;
        this.email = user.email;
        this.$root.$refs.plansOptions = this;
        EventBus.$on('dataForPayment', (data) => {
            this.paymentData = data;
        });
    },
    methods: {
        plansOptionsModal() {
            $("#plansOptions")
            .addClass("fade")
            .modal("show");
        },
        closePlansModal() {
            return this.$root.$refs.plans.closePlansModal();
        },
        processPayment(response) {
            this.paymentLoader = true;
            const ref = response.reference;
            if (response.status === "success") {
                let body = {
                        smart_plan_id: this.paymentData.id,
                        payment_method: 'paystack',
                        amount:  this.paymentData.amount,
                        referencecode: ref,
                    }
                body = JSON.stringify(body);
                plansbillings.billPayment(body)
                .then((res) => {
                    this.paymentLoader = false;
                    // console.log("Subscription response", res);
                    if (res.success === "success") {
                    location.reload();
                    $("#plansOptions")
                        .removeClass("fade")
                        .modal("hide");
                        this.$toast.success("Payment successful");
                    }
                    if (res.error) {
                        this.paymentLoader = false;
                        this.$toast.error(res.error);
                        $("#plansOptions")
                        .removeClass("fade")
                        .modal("hide");
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                    this.$toast.error(err);
                    // return this.$store.commit("setApiFailed", "Error occured");
                });
            }
        },
        submitPayment() {
            this.paymentLoader = true;
            let body = {
                smart_plan_id: this.paymentData.id,
                payment_method: this.activePayment,
                amount:  this.paymentData.amount,
            }
            body = JSON.stringify(body);
            plansbillings.billPayment(body)
            .then((res) => {
                // console.log("Payment response", res);
                this.paymentLoader = false;
                if (res.success === "success") {
                location.reload();
                $("#plansOptions")
                    .removeClass("fade")
                    .modal("hide");
                    this.$toast.success("Payment successful");
                }
                if (res.error) {
                    this.$toast.error(res.error);
                    this.paymentLoader = false;
                    $("#plansOptions")
                    .removeClass("fade")
                    .modal("hide");
                }
            })
            .catch((err) => {
                this.paymentLoader = false;
                console.log("err", err);
                this.$toast.error(err);
                // return this.$store.commit("setApiFailed", "Error occured");
            });

        },
        // Paystack close function or cancel payment
        close: () => {
        // console.log("You closed checkout page");
        },
    },
}
</script>


<style scoped lang="scss">
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;


.modal-top-text {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;

}

.amount-section {
    margin-bottom: 25px;
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: $offblack;
    }
    h3 {
        font-family: Lato;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        color: $offblack
    }
    p {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: $offblack
    }
}

.payment-option-list {
    display: flex;
    align-items: center;
    height: 64px;
    border: 1px solid #E4E7EC;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 0 15px;
    button {
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: $asheGrey;
        outline: 0;
        box-shadow: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
            margin-right: 15px;
        }
    }
}

.agree-btn {
    margin-top: 40px;
    margin-bottom: 20px;
    button {
        color: $white;
        background: $primary;
        outline: 0;
        box-shadow: none;
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        border-radius: 8px;
        height: 48px;
        width: 100%;
    }
}
.change {
    background: none !important;
    border: 1px solid $primary !important;
    color: $primary !important;
    margin-bottom: 10px;
}

.rouded-cycle {
    width: 16px;
    height: 16px;
    border-radius: 50px;
    border: 1px solid #D0D5DD;
}

.activeBtn {
    border: 1px solid $primary;
}

.transfer-holder {
    background: #F5F5F7;
    border-radius: 8px;
    padding: 20px 15px;
}
.bank-details {
    h6, h5 {
        font-family: Lato;
        font-size: 14px;
        line-height: 24px;
        text-align: left;
    }
    h6 {
        font-weight: 400;
        color: $asheGrey;
        font-weight: bold;
        font-size: 16px !important;
    }
    h5 {
        font-weight: 500;
        color: $offblack;
        white-space: nowrap;
    }
}

.directionBtns {
    margin-top: 40px;
    button {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        box-shadow: none;
        outline: 0;
        width: 100%;
        height: 48px;
    }
    .confirm {
        background: $primary;
        color: $white;
        margin-bottom: 10px;
    }
    .change {
        border: 1px solid $primary;
        color: $primary;
        margin-bottom: 30px;
    }
}
</style>